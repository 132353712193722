import React from "react"
import { navigate } from "gatsby"
import {
  chakra,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import Detail from "./detail"

const styles = {
  root: {
    height: "100%",
    width: "100%",
    px: "24px",
    mt: { base: "32px", md: "32px", lg: "64px" },
    mb: "65px",
    textAlign: "center",
  },
  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },

  heading: {
    fontWeight: "700",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#041424",
    mb: { base: "32px", md: "32px", lg: "48px" },
  },
  text: {
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#000000",
    mb: "10px",
  },
  bold: {
    fontWeight: "bold",
  },
  "title-text": {
    fontSize: { base: "20px", md: "20px", lg: "32px" },
    lineHeight: { base: "28px", md: "28px", lg: "40px" },
    fontWeight: { base: "500", md: "500", lg: "700" },
    color: "#041424",
    mb: "15px",
  },
  "text-box": {
    pt: { base: "80px", md: "80px", lg: "0px" },
    pb: { base: "40px", md: "40px", lg: "0px" },
    width: "100%",
    height: "auto",
    flexDirection: "column",
    alignItems: "center",
  },
  "detail-box": {
    verticalAlign: "center",
  },
}
const navigateToDemo = () => navigate("/schedule-a-demo/")

const SectionSeeDetails = () => {
  return (
    <Box id="see-the-details" sx={styles.root}>
      <Flex sx={styles["text-box"]}>
        <Heading sx={styles.heading}> See the Details</Heading>

        <Box sx={{ width: { base: "100%", md: "100%", lg: "50%" } }}>
          <Text sx={styles["title-text"]}>
            Your hunch about what needs to change is probably right. At least,
            you hope it is.
          </Text>
          <Text sx={styles.text}>
            You think you know what it would take to cut costs in the OR, or
            reduce the time in the PACU, or lower the likelihood of
            readmissions. But all you have are pages and pages of incomplete
            data and some equally unhelpful, albeit colorful, charts. Meanwhile,
            everyone on your team has a different idea about what to do next.
            But no one has the details to make a strong case or a truly
            well-informed decision.
          </Text>
          <Text sx={{ mb: "20px" }}></Text>
          <Text>
            <chakra.span sx={styles.bold}>
              With Avant-garde Health, you can see the details.{" "}
            </chakra.span>
            Drill down to a level of clinical, financial, and operational detail
            that you weren't able to see before.
          </Text>
        </Box>
      </Flex>
      <Grid
        justifyItems="center"
        alignItems="center"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
        }}
        width={{ base: "100%", md: "100%", xl: "1200px" }}
        margin="0 auto"
        mb="32px"
      >
        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-360.png"
                alt="Get a more complete picture icon"
                placeholder="blurred"
              />
            )}
            title="Get a more complete picture"
            text={["by combining EMR, financial, and PRO data"]}
          />
        </GridItem>

        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-drill.png"
                alt="Quickly drill into specific details icon"
                placeholder="blurred"
              />
            )}
            title="Quickly drill into specific details"
            text={["with a user-friendly interface"]}
          />
        </GridItem>

        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-beyond.png"
                alt="Go beyond aggregate level figures icon"
                placeholder="blurred"
              />
            )}
            title="Go beyond high-level aggregate figures"
            text={["to see actual costs at the item level"]}
          />
        </GridItem>

        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-track.png"
                alt="Track information icon"
                placeholder="blurred"
              />
            )}
            title="Track information"
            text={["by surgeon, procedure, facility, supply, and more"]}
          />
        </GridItem>

        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-analyze.png"
                alt="Analyze specific clinical outcomes icon"
                placeholder="blurred"
              />
            )}
            title="Analyze specific clinical outcomes "
            text={["such as PACU time and readmissions"]}
          />
        </GridItem>
        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-measure.png"
                alt="Measure costs more effectively icon"
                placeholder="blurred"
              />
            )}
            title="Measure costs more effectively "
            text={["with built in Time-Driven Activity-Based Costing (TDABC)"]}
          />
        </GridItem>
      </Grid>
      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          width: "211px",
          height: "56px",
          margin: "0 auto",
        }}
      >
        <Button
          title="Click here to take a closer look at our healthcare data analytics software in action."
          sx={styles.button}
          onClick={navigateToDemo}
        >
          Take a closer look
        </Button>
      </Flex>
    </Box>
  )
}

export default SectionSeeDetails
