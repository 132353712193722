import React from "react"
import { Link as GatsbyLink, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Button, Flex, Heading, Link, Text } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "494px" },
    width: "100%",
    bg: "#F4F4F4",
    px: { base: "24px", md: "24px", lg: "10%" },
  },
  "inner-box": {
    mt: "0px",
    width: "100%",
    height: "100%",
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: "center",
    justifyContent: "center",
  },
  "left-box": {
    maxWidth: { base: "100%", md: "100%", lg: "428px" },
    flexDirection: "column",
  },
  heading: {
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "48px" },
    lineHeight: { base: "32px", md: "32px", lg: "56px" },
    color: "#000000",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  text: {
    mt: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#000000",
  },
  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
  image: {
    ml: { base: "0px", md: "0px", lg: "16px" },
    height: { base: "auto", md: "auto", lg: "371px" },
    minWidth: { base: "100%", md: "100%", lg: "429px" },
    maxWidth: { base: "100%", md: "100%", lg: "429px" },
  },
  "icon-box": {
    display: { base: "flex", md: "flex", lg: "none" },
    my: "17px",
    width: "100%",
    justifyContent: "center",
  },
  icon: {
    display: { base: "flex", md: "flex", lg: "none" },
    color: "#9CACBC",
    fontSize: "42px",
  },
  whitespace: {
    pb: "15px",
  },
}

const navigateToDemo = () => navigate("/schedule-a-demo/")

const SectionHero = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["inner-box"]}>
      <Flex sx={styles["icon-box"]}>
        <ChevronDownIcon sx={styles.icon} />
      </Flex>
      <Flex sx={styles["left-box"]}>
        <Heading sx={styles.heading}>CareMeasurement to the Rescue</Heading>
        <Text sx={styles.text}>
          For too long, you've had more data than you know what to do with, but
          still no way to spot all the factors that impact clinical outcomes and
          costs. That's why it's so hard for everyone to figure out - much less
          agree on - where to make changes.
        </Text>
        <Text sx={styles.whitespace}></Text>
        <Text sx={styles.text}>
          With our unique "See the Details, Compare the Findings, Develop the
          Playbooks”- approach, Avant-garde Health gives you the level of detail
          you can't see now.
        </Text>
        <Button sx={styles.button} onClick={navigateToDemo}>
          Ready to take a closer look?
        </Button>
      </Flex>
      <Link as={GatsbyLink} to={"/schedule-a-demo/"}>
        <Box sx={styles.image}>
          <StaticImage
            src="../../images/caremeasurement/cm-hero.jpeg"
            alt="Image of a physician and a happy patient. Click here to take a closer look at our healthcare data analytics software in action."
            placeholder="blurred"
          />
        </Box>
      </Link>
    </Flex>
  </Box>
)

export default SectionHero
